<template lang="html" src="./pageOrders.template.vue"></template>

<style lang="scss" src="./pageOrders.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter , CustomFilterForSearchDatatable} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageOrders.i18n');

export default {
  name: 'PageOrders',
  components: { DataTableFilter , CustomFilterForSearchDatatable},
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      orders: [],
      filterObject: {
        status: [
          {
            name: this.$t('NEWORDER'),
            value: 'NEW', 
            img: 'new.png',
            number: 0,
            selected: !Object.keys(this.$route.query).length || !!this.$route.query.new
          },
          {
            name: this.$t('INPROGRESS'),
            value: 'INPROGRESS', 
            iconColor: 'primary',
            icon: 'fas fa-spinner',
            number: 0,
            selected: !Object.keys(this.$route.query).length || !!this.$route.query.inprogress
          },
          {
            name: this.$t('DELIVERED'),
            value: 'DELIVERED', 
            iconColor: 'success',
            icon: 'fas fa-check',
            number: 0,
            selected: !!this.$route.query.delivered
          },
          {
            name: this.$t('CANCELED'),
            value: 'CANCELED', 
            iconColor: 'error',
            icon: 'fas fa-ban',
            number: 0,
            selected: !!this.$route.query.canceled
          }
        ],
        paymentStatus: [
          {
            name: this.$t('WAITING'),
            value: 'WAITING', 
            img: 'creditCard.svg',
            number: 0,
          },
          {
            name: this.$t('PAID'),
            value: 'PAID', 
            img: 'creditCardCheck.svg',
            number: 0,
          },
          {
            name: this.$t('ERROR'),
            value: 'ERROR', 
            img: 'creditCardTimes.svg',
            number: 0,
          },
        ]
      },
      loading: false,
      loadingTable: false,
      search: '',
      statusFilter: [],
      paymentFilter: [],
      typeFilter: [],
      headers: [],
    };
  },
  created() {
    this.getBrandOrders();
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        filter: (value) => {
          if (this.statusFilter.length === 0) return true;
          if (this.statusFilter.includes(value)) {
            return true;
          }
        },
      },
      {
        text: '',
        sortable: false,
        value: 'paymentStatus',
        filter: (value) => {
          if (this.paymentFilter.length === 0) return true;
          if (this.paymentFilter.includes(value)) {
            return true;
          }
        },
      },
      {
        text: this.$t('invoiceID'),
        value: 'invoiceNumber',
        align: 'center',
      },
      {
        text: this.$t('purchaseOrder'),
        value: 'quote.purchaseOrderNumber',
        align: 'center',
        sort: this.sortPurchaseOrderFn
      },
      {
        text: this.$t('price'),
        value: 'quote.selling.totalAmount',
        align: 'center',
      },
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('items'), value: 'quote.items', class: 'd-none', align: ' d-none' },
      {
        text: this.$t('action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    if(this.$appConfig.brand.features.privateSuppliers.length) {
      this.filterObject['type'] = [
        {
          name: 'CMT',
          value: 'public', 
          img: 'logo_cut.png',
          number: 0,
        },
        {
          name: this.$t('PrivateSupplier'),
          value: 'private', 
          iconColor: 'privateSupplier',
          icon: 'fas fa-warehouse',
          number: 0,
        }
      ];

      this.headers.splice(1, 0, {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      });
    }
  },
  methods: {
    sortPurchaseOrderFn(cf1, cf2) {
      const po1 = cf1?.[0]?.StringValue;
      const po2 = cf2?.[0]?.StringValue;
      if(po1 === po2) {
        return 0;
      }
      return po1 < po2 ? 1 : -1;
    },
    customFilter(items, search, filter) {
      if (typeof items === 'number' ) {
        items = items.toString().replace('.', ',');
      }
      let partsList = null;
      if (Array.isArray(items) 
        && items[0] && Object.getPrototypeOf(items[0]) 
        && Object.getPrototypeOf(items[0]).constructor.name === 'OrderItem') {
        if (!partsList) partsList = '';
        items.forEach((item) => {
          partsList += item.part.name;

        });
      }
      if (partsList) items = partsList;
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    getBrandOrders() {
      this.loadingTable = true;
      /**
       * GET BRAND ORDERS
       */
      this.$apiInstance
        .getBrandOrders(this.$route.params.brandUUID)
        .then((data) => {
          this.orders = data;
          /**
           * Reset Filter Object
           */
          for (const key in this.filterObject) {
            for (const obj of this.filterObject[key]) {
              obj.number = 0;
            }
          }
          this.orders.forEach(order => {
            this.filterObject.status.find(x => x.value === order.status).number++;
            const paymentStatusObject = this.filterObject.paymentStatus.find(x => x.value === order.paymentStatus && order.status !== 'CANCELED');
            if(paymentStatusObject) {
              paymentStatusObject.number++;
            }
            if(this.$appConfig.brand.features.privateSuppliers.length) {
              this.filterObject.type.find(x => (x.value === 'public' && order.public) || (x.value === 'private' && !order.public)).number++;
            }
          });
        })
        .catch((error) => {
          /**
           * ERROR GET BRAND ORDERS
           */
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        })
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    refreshPage() {
      this.loading = true;
      this.getBrandOrders();
    },
    updateFilter(update) {
      this.statusFilter = update.status;
      this.paymentFilter = update.paymentStatus;
      if(update.type) {
        this.typeFilter = update.type;
      }
      if (this.paymentFilter.length > 0 && !this.statusFilter.includes('NEW') && !this.paymentFilter.includes('PAID')) {
        this.statusFilter.push('NEW');
      }
      this.filterObject.status.forEach(status => {
        if(this.paymentFilter.length > 0 && !this.paymentFilter.includes('PAID') && status.value !== 'NEW' ) {
          status.disabled = true;
          this.statusFilter.length = 0;
          this.statusFilter.push('NEW');
        } else {
          status.disabled = false;
        }
      });
    },
    goToPageItem(order) {
      this.$router.push({name: 'Order', params: {brandUUID: `${this.$appConfig.currentBrand}`, orderUUID: `${order._id}`}});
    },
  },
};
</script>
