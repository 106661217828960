var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-orders"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('orders')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage')
    },
    on: {
      "click": _vm.refreshPage
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', {
    staticClass: "ordersTableCard px-10"
  }, [_c('v-card-title', {
    staticClass: "px-0"
  }, [_c('DataTableFilter', {
    ref: "filter",
    staticClass: "col-11 col-lg-8 pl-0",
    attrs: {
      "width-of-filters-titles": "3",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": `${_vm.$t('Search')}`,
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "loading": _vm.loadingTable,
      "headers": _vm.headers,
      "items": _vm.orders,
      "custom-filter": _vm.customFilter,
      "search": _vm.search,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function ($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.created`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            },
            "title": item.created
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")])];
      }
    }, {
      key: `item.status`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            }
          }
        }, [item.status === 'NEW' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-credit-card ")]), item.paymentStatus === 'PAID' ? _c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-check-circle ")]) : item.paymentStatus === 'ERROR' ? _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" far fa-times-circle ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t(item.paymentStatus)) + " ")] : item.status === 'SUBMITTED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "warning"
          }
        }, [_vm._v(" far fa-calendar-alt ")]), _vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")] : item.status === 'INPROGRESS' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-spinner ")]), _vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")] : item.status === 'DELIVERED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")] : item.status === 'CANCELED' ? [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t(item.status)) + " ")] : _vm._e()], 2)];
      }
    }, {
      key: `item.paymentStatus`,
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: `item.public`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                quoteUUID: `${item._id}`
              }
            },
            "title": item.public ? 'CMT' : _vm.$t('PrivateSupplier')
          }
        }, [item.public ? _c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "src": require('/src/assets/logo_cut.png'),
            "lazy-src": require('/src/assets/logo_cut.png'),
            "contain": "",
            "width": "30",
            "height": "30"
          }
        }) : _c('v-icon', {
          attrs: {
            "small": "",
            "color": "privateSupplier"
          }
        }, [_vm._v(" fas fa-warehouse ")])], 1)];
      }
    }, {
      key: `item.invoiceNumber`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" n°" + _vm._s(item.invoiceNumber) + " ")])];
      }
    }, {
      key: `item.quote.purchaseOrderNumber`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            }
          }
        }, [item.quote.purchaseOrderNumber ? [_vm._v(" " + _vm._s(item.quote.purchaseOrderNumber) + " ")] : _vm._e()], 2)];
      }
    }, {
      key: `item.quote.selling.totalAmount`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            }
          }
        }, [item.billing ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(item.quote.selling.totalAmount, 'currency', 'fr')) + " ")]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v(" " + _vm._s(_vm.$t('NotApplicable')) + " ")])])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          staticClass: "primary",
          attrs: {
            "to": {
              name: 'Order',
              params: {
                brandUUID: `${_vm.$appConfig.currentBrand}`,
                orderUUID: `${item._id}`
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          staticClass: "far fa-eye",
          attrs: {
            "small": ""
          }
        })], 1)];
      }
    }], null, true)
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }